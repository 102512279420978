<template>
  <div class="pt-60 pb-24">
    <div class="text-left container mx-auto">
      <h1 class="custom-heading h-custom-color">
        Všeobecné obchodné podmienky
      </h1>
      <p class="p-custom-color custom-paragraph mr-20">
        Posledná aktualizácia 9.8.2024
      </p>
      <h1 class="custom-heading h-custom-color mt-20">0. Definícia pojmov</h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Prevádzkovateľom služby je spoločnosť HostNow s.r.o., IČO: 21788693,
          DIČ: CZ21788693, so sídlom Jánský vršek 323/13, 118 00, Praha 1 - Malá
          Strana, Česká republika. Spoločnosť je možné kontaktovať pomocou
          e-mailu info@hostnow.cz alebo datovej schránky s ID 6znwuvh.
          Spoločnosť je zapísaná v obchodnom rejstříku vedenom Městským soudem v
          Praze oddíl C, vložka 406087.
        </li>
        <li>
          Zákazník, ďalej ako použivateľ, uživateľ alebo kupujúci, je každý, kto
          sa zaregistruje na stránkach HostNow
        </li>
        <li>
          Všeobecné obchodné podmienky, ďalej ako VOP, sú tieto Všeobecné
          obchodné podmienky, s ktorými použivateľ súhlasí pri registrácií
        </li>
        <li>
          Administrátori stránky, ďalej aj ako administrátori, administrátor
          alebo prevádzkovateľ, sú osoby, ktoré zodpovedajú a zaisťujú chod
          stránky a starajú sa o ňu
        </li>
        <li>
          Webová stránka, ďalej aj ako web alebo uverejnený obsah, je obsah,
          ktorí zákazníci nahrajú na naše servery a prostredníctvom našich
          služieb majú na svojich webových stránkach
        </li>
      </ol>

      <h1 class="custom-heading h-custom-color mt-20">I. Úvodné ustanovenia</h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          HostNow, dostupný na stránke https://hostnow.cz/ je práca chránená
          autorskými právami prislúchajúcimi autorom HostNow. Niektoré časti
          stránky môžu podliehať dodatočným pravidlám, smerniciam a podmienkam,
          ktoré budú uverejnené na danej stránke, ktorá nadväzuje na tieto
          ustanovenia.
        </li>
        <li>
          Všetky všeobecné pravidlá, podmienky a smernice sú vpísané do týchto
          podmienok. Tieto všeobecné obchodné podmienky zahŕňajú všetky legálne
          späté termíny a podmienky ktoré dohliadajú na použitie tejto stránky.
        </li>
        <li>
          Prihlásením sa alebo registráciou do tejto stránky súhlasíte s týmito
          podmienkami a potvrdzujete, že máte dostatočné právo a kapacitu na
          súhlasenie s týmito podmienkami. Mali by ste byť aspoň 18 ročný
          a/alebo mať súhlas zákonného zástupcu na používanie tejto stránky.
        </li>
        <li>
          Pokiaľ nesúhlasíte s týmito podmienkami, neprihlasujte sa do tejto
          stránky a/alebo ju nepoužívajte. Pokiaľ máte akékoľvek pripomienky,
          kontaktujte nás na info@hostnow.cz
        </li>
      </ol>
      <h1 class="custom-heading h-custom-color mt-20">II. Popis služieb</h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Služba "Webhosting" je služba, ktorá zákazníkovi umožňuje nahrať obsah
          na svoju doménu a urobiť ho dostupným na internete.
        </li>
      </ol>
      <h1 class="custom-heading h-custom-color mt-20">
        III. Pravidlá zákazníkov
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Predmetom služieb poskytovateľa nie je kontrola obsahu web stránok
          zákazníka. Poskytovateľ nezodpovedá za obsah web stránok, sprístupnený
          a uverejnený na základe poskytnutia služieb poskytovateľa podľa týchto
          VOP uživateľom. Uživateľ nezodpovedá za činnosť poskytovateľa, pokiaľ
          táto činnosť nebola uskutočnená na žiadosť, pokyn alebo v záujme
          uživateľa. Táto žiadosť alebo pokyn môže byť vydaná písomne,
          elektronicky prostredníctvom administračného rozhrania hostingu,
          e-mailom, telefonicky alebo iným obdobným preukázateľným spôsobom.
        </li>
        <li>
          Obsah ktorý zákazník nahrá na naše servery a zverejní na svojej
          webovej stránke nesmie nijak porušovať zákony Slovenskej Republiky a
          Európskej Únie, iné platné medzinárodné práva a pravidlá zákazníkov.
          Za tento uverejnený obsah má úplnu zodpovednosť zákazník, ktorý ho
          nahral alebo vytvoril. Poskytovateľ neberie žiadnu zodpovednosť za
          tento obsah.
        </li>
        <li>
          Poskytovateľ nezodpovedá za škodu, spôsobenú uživateľovi treťou
          osobou, ktorá násilne alebo využitím chýb v uživateľových aplikáciach
          alebo nastaveniach prenikla na www stránku uživateľa a spôsobila škodu
          alebo skopírovala, pozmenila alebo vymazala uložené dáta užiovateľa.
          Poskytovateľ takisto nezodpovedá za škodu spôsobenú treťou osobou,
          ktorá bez zavinenia poskytovateľa získa neoprávnený prístup do
          počítačového systému, k inému nosiču informácií alebo jeho časti a
          vkladaním, prenášaním poškodením, vymazaním znížením kvality,
          pozmenením alebo potlačením počítačových dát marí funkčnosť
          počítačového systému alebo vytvára neautentické dáta s úmyslom, aby sa
          považovali za autentické alebo aby sa s nimi takto na právne účely
          nakladalo a to ani v prípade, ak toto konanie nezakladá trestný čin
          podľa Trestného zákona.
        </li>
        <li>
          Obsah uverejnený zákazníkom nesmie nijak poškodzovať dobŕe meno
          hostingu.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať erotický
          a pornografický obsah (text, obrázky, videá apod.).
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať obsah
          nabádajúci k národnostnej, náboženskej, rasovej alebo etnickej
          neznášanlivosti šíriaci alebo podnecujúci extrémizmus, propagujúci
          fašizmus, komunizmus alebo iné podobné ideológie.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať dáta
          obsahujúce warez alebo odkazujúce na servery so zameraním na warez
          alebo podobným zameraním.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať download
          servery.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať
          nelegálne získaný softvér za účelom ďaľšieho predaja alebo
          poskytovania tretím osobám.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať servery
          zameriavajúce sa na šírenie nevyžiadanej pošty (spamu)
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať nadmerné
          množstvo hudobných a filmových súborov (mp3, mpeg, avi, mp4)
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa
          neumiestňovať&nbsp;odkazy, údaje a dáta týkajúce sa hazardnej hry,
          ktorej nebola udelená licencia podľa právnych predpisov platných na
          území Slovenskej republiky, vrátane poskytovania zakázanej ponuky
          hazardnej hry, propagovania alebo prevádzkovania hazardnej hry
          dostupnej na území Slovenskej republiky bez licencie podľa osobitného
          zákona.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa
          neumiestňovať&nbsp;akékoľvek odkazy, údaje a dáta akýmkoľvek iným
          spôsobom porušujúce zákony Slovenskej republiky alebo dobré mravy.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa
          neumiestňovať&nbsp;akékoľvek servery, ktoré sa i čiastočne dajú
          zaradiť do vyššie uvedených kategórií.
        </li>
        <li>
          Zákazník je povinný na zariadenia poskytovateľa neumiestňovať obsah
          zjavne zasahujúci do osobných a osobnostných práv tretích osôb, práv
          na ochranu osobnosti, na ochranu dobrého mena právnickej osoby.
        </li>
        <li>
          Zákazník má zakázané využívať alebo zneužívať chyby stránky hostnow.cz
          a musí ich okamžite nahlásiť. Zákazník sa nesmie pokúšať napadnúť
          servery HostNow útokom DDoS, alebo vykonávať iné útoky voči týmto
          serverom.
        </li>
        <li>Zákazník ma povinnosť uviesť pravdivé údaje do svojho profilu.</li>
        <li>
          Zákazník sa nesmie nijak pokúšať poškodiť meno HostNow.cz šírením
          nepravdivých správ ani žiadnym iným spôsobom, ktorý zahrňuje klamstvá
          a zveličenú realitu.
        </li>
        <li>
          Zákazník sa registrovaním na stránkach HostNow vzdáva akéhokoľvek
          práva na podanie žaloby voči webhostingu HostNow.
        </li>
        <li>
          Zákazník je povinný nevyužívať dátový priestor a prenosové kapacity na
          účely skladovania a archívovania prípadne zálohovania iných dát, ktoré
          nesúvisia s webovou stránkou zákazníka, prípadne ako priestor na
          presúvanie dát medzi uživateľmi.
        </li>
        <li>
          Zákazník je povinný nevyužívať priestor služieb spôsobom, ktorý by
          neprimerane obmedzoval ďalších zákazníkov poskytovateľa pri využívaní
          týchto služieb, či inak neprimerane obmedzovať poskytovateľa.
        </li>
        <li>
          Užívateľ si je vedomý svojej prípadnej trestno-právnej zodpovednosti v
          zmysle zákona č. 300/2005 Z. z. v znení neskorších predpisov (Trestný
          zákon) za konania v rozpore s ustanoveniami uvedeného zákona.
        </li>
        <li>
          Pokiaľ zákazník nemá na svojom účte dostatok kreditu, budú mu jeho
          služby zamrazené a pokiaľ ich do 7 dní neobnoví, budú zmazané bez
          nároku na obnovu.
        </li>
        <li>
          Zákazník je povinný nevyťažovať výkonnosť serverov HostNow bez zmyslu
          a v prípade, ak chce na servery HostNow umiestniť náročnejší projekt,
          je povinný s tým oboznámiť zákaznícku podporu.
        </li>
        <li>
          Každý zákazník má povolené vlastniť iba 1 účet na stránkach HostNow. V
          opačnom prípade je povinný s tým oboznámiť zákaznícku podporu
          prostredníctvom emailovej adresy info@hostnow.cz alebo prostredníctvom
          ticketoveho systému v administrácií.
        </li>
        <li>
          Zákazník má striktne zakázané šíriť z webovej stránky hostovanej na
          webhostingu HostNow spam, ktorý má za účelom kradnúť dáta a/alebo
          prihlasovacie údaje do iných služieb.
        </li>
        <li>
          Zákazník ma striktne zakázané uverejňovať na svojich webových
          stránkach nelegálny obsah.
        </li>
        <li>
          V prípade porušenia týchto pravidiel je zákazník nútený nahradiť
          všetku spôsobenú škodu do 30 kalendárnych dní od jej preukázania, a to
          bezhotovostným prevodom na účet poskytovateľa, ak sa zmlúvne strany
          nedohodnú inak. Pokiaľ sa jedná o nelegálnu činnosť, ktorú zákazník
          vykonával na serveroch HostNow bude zákazníkovi vyvodená zodpovednosť
          a autority budú oboznámené.
        </li>
        <li>
          Zákazník ma zakázané zneužívať chyby HostNow a je povinný okamžite o
          všetkých chybách oznámiť ich zákazníckej podpore.
        </li>
        <li>
          Zákazník má zakázané zneužívať služby zákazníckej podpory a tvoriť
          falošné žiadosti.
        </li>
        <li>
          Zákazník má povinnosť pokryť všetky spôsobené škody zapríčinené jeho
          nepatrnosťou.
        </li>
        <li>
          Zákazník má povinnosť nevytvárať projekty a neobjednávať si služby pod
          doménou, ktorej vlastníctvo nedokáže preukázať.
        </li>
      </ol>
      <h1 class="custom-heading h-custom-color mt-20">IV. Práva zákazníkov</h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Použivateľ má právo nakupovať kredit na stránkach HostNow avšak nesmie
          ho nijak predávať.
        </li>
        <li>
          Zákazník má právo žiadať vrátenie pomernej časti Ceny za dobu
          neposkytovania služieb v prípade zavinenia poskytovateľom, o vrátenie
          ktorej uživateľ požiada poskytovateľa najneskôr do jedného mesiaca od
          prerušenia poskytovania služieb, alebo na predĺženie doby poskytovania
          služieb o 1 deň za každé prerušenie poskytovania služby v predplatenom
          období zavinené poskytovateľom alebo jeho subdodávateľom, ktoré trvalo
          dlhšie ako 12 hodín, pokiaľ sa nedohodli inak. Vrátenie ceny je
          výlučne vo forme vrátenia kreditov na účet zákazníka.
        </li>
        <li>
          Zákazník má právo vymazať všetok obsah, ktorý uverejnil alebo nahral
          na servery HostNow, avšak tak stráca právo na akékoľvek vrátenie dát,
          ktoré svojou akciou zmazal tiež.
        </li>
        <li>
          Zákazník ma právo kontaktovať zákaznícku podporu HostNow na emailovej
          adrese info@hostnow.cz alebo prostredníctvom ticketového systému v
          administrácií pri dodržaní Pravidiel použivateľov.
        </li>
        <li>
          Zákazník má právo vymazať všetok obsah, ktorý uverejnil alebo nahral
          na servery HostNow, avšak tak stráca právo na akékoľvek vrátenie dát,
          ktoré svojou akciou zmazal tiež.
        </li>
        <li>
          Použivateľ má právo vyjadriť nespokojnosť so službami HostNow
          odoslaním správy na emailovú adresu info@hostnow.cz alebo
          prostredníctvom ticketového systému v administrácií. Zákaznícka
          podpora sa snaží všetkým sťažnostiam vyhovieť a robíme čo je v našich
          silách aby sme zaručili spokojnosť našich zákazníkov.
        </li>
        <li>
          Zákazník má právo vyžiadať si úplne vymazanie všetkych svojich
          záznamov a dát zo serverov HostNow.
        </li>
        <li>
          Zákazník má plné právo využívať služby HostNow pokiaľ dodržuje
          pravidlá zákazníkov.
        </li>
      </ol>

      <h1 class="custom-heading h-custom-color mt-20">
        V. Pravidlá zákazníckej podpory
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Zákaznícka podpora nesmie od zákazníka žiadať akékoľvek prihlasovacie
          údaje
        </li>
        <li>
          Zákaznícka podpora nesmie bez dôvodu prehľadávať projektové súbory
          zákazníkov.
        </li>
      </ol>
      <h1 class="custom-heading h-custom-color mt-20">
        VI. Práva zákazníckej podpory
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Zákaznícka podpora má právo zamietnuť žiadosť zákazníka v prípade ak
          obsahuje vulgarizmy, je neúctivá, obsahuje satiru alebo inak znevažuje
          zákaznícku podporu.
        </li>
        <li>
          Zákaznícka podpora má právo označiť žiadosť ako vyriešenú v prípade,
          že zákazník je neaktívny po dobu 7 dní.
        </li>
        <li>
          Zákaznícka podpora má právo zrušiť projekty zákazníka v prípade, že
          porušujú pravidlá zákazníkov.
        </li>
        <li>
          Zákaznícka podpora má právo zamietnuť žiadosť zákazníka pokiaľ usúdi,
          že je nevhodná alebo porušuje pravidlá zákazníkov.
        </li>
      </ol>
      <h1 class="custom-heading h-custom-color mt-20">
        VII. Práva prevádzkovateľa a administrátorov
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Prevádzkovateľ a administrátori, ďalej len ako administrátori, majú
          plné právo zmazať akýkoľvek zákaznícky projekt bez udania dôvodu.
        </li>
        <li>
          Administrátori majú plné právo zmazať akýkoľvek zákaznícky účet bez
          udania dôvodu.
        </li>
        <li>Administrátori majú plné právo upraviť VOP bez oznámenia.</li>
        <li>
          Administrátori majú plné právo kedykoľvek zmeniť alebo úplne zrušiť
          aktuálnu ponuku. Musia to však oznámiť použivateľovi najneskôr týždeň
          pred samotným zrušením / zmenením služby.
        </li>
        <li>
          Administrátori majú plné právo kedykoľvek dočasne obmedziť, zmeniť
          alebo upraviť projekt zákazníka, v prípade ak poskytovateľ z dôvodu
          problémov nie je v aktuálnej situácií schopný poskytnúť zákazníkom
          stabilné služby ako bolo sľúbené.
        </li>
        <li>
          Administrátori majú plné právo kedykoľvek dočasne obmedziť, zmeniť
          alebo upraviť projekt zákazníka, v prípade ak poskytovateľ z dôvodu
          problémov nie je v aktuálnej situácií schopný poskytnúť zákazníkom
          stabilné služby ako bolo sľúbené.
        </li>
        <li>
          Administrátori majú plné právo odmietnuť poskytovanie služieb
          uživateľovi, pokiaľ mu už predtým bolo poskytovanie služieb dočasne
          pozastavené alebo poskytovateľ podnikajúci v tom istom odbore odstúpil
          od zmluvy pre opakované alebo závažné neplnenie povinností uživateľom
        </li>
        <li>
          Poskytovateľ a administrátori majú plné právo informovať zákazníka o
          produktoch týkajúcich sa služby a iných aktivitách poskytovateľa
          e-mailom alebo iným vhodným spôsobom. Takáto informácia sa nepovažuje
          za spam.
        </li>
      </ol>

      <h1 class="custom-heading h-custom-color mt-20">
        VIII. Povinnosti prevádzkovateľa a administrátorov
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Prevádzkovateľ a administrátori, ďalej len ako administrátori, sú
          zodpovední za chod serverov a sú povinní zabezpečovať funkčnosť
          hostingu.
        </li>
        <li>
          Administrátori sú povinní aktualizovať programové vybavenie servera, a
          to z hľadiska funkčnosti aj bezpečnosti servera.
        </li>
        <li>
          Administrátori sú povinní&nbsp;vykonávať opatrenia na zníženie alebo
          úplné odstránenie možných výpadkov v poskytovaní služieb hostingu.
        </li>
        <li>
          Administrátori sú povinní vykonávať plánované preventívne odpojenie
          služieb s cieľom kontroly, údržby či výmeny hardvéru, prípadne
          nastavenia alebo aktualizácie programového vybavenia servera, ďalej
          len ako výpadok služieb.
        </li>
        <li>
          Administrátori sú povinní informovať o plánovanom výpadku služieb na
          svojej webovej stránke, prostredníctvom emailov, na Discord servery
          hostingu alebo iným vhodným spôsobom.
        </li>
        <li>
          Administrátori sú povinní plniť ďaľšie povinnosti vyplývajúce z týchto
          VOP a z Podmienok ochrany súkromia.
        </li>
        <li>
          Administrátori sú povinní informovať zákazníka bez zbytočného odkladu
          o plánovaných obmedzeniach, prerušeniach alebo zmenách v poskytovaní
          služieb alebo plánované opatrenia prevádzkované v sieti internet a na
          zariadeniach poskytovateľa alebo tretej osoby, ktoré môžu ovplyvniť
          poskytovanie služieb.
        </li>
      </ol>

      <h1 class="custom-heading h-custom-color mt-20">
        IX. Komunikácia medzi zákazníkom a poskytovateľom
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Komunikácia medzi zmlúvnymi stranami prebieha v prvom rade v
          elektronickej forme prostredníctvom administračného rozhrania
          hostingu(inak nazvané tiež aj ticketovým systémom) alebo
          prostredníctvom emailov (objednávky, žiadosti o zmeny, potvrdenie
          platby, aktivačné informácie, zasielanie zálohových faktúr a podobne).
        </li>
        <li>
          Poskytovateľ nenesie žiadnu zodpovednosť za prerušenia poskytovania
          služieb alebo iné riziká, vyplývajúce z nesprávne alebo neaktuálne
          zadaných údajov (napr. nemožnosť doručenia zálohovej faktúry na
          nesprávne nastavenú kontaktnú e-mailovú adresu, prípadne neexistujúcu
          e-mailovú adresu). Kontaktná e-mailová adresa užívateľa musí byť
          <strong>funkčná a pravidelne kontrolovaná užívateľom</strong>.
        </li>
        <li>
          Uživateľ je povinný pri úhrade uvádzať správny variabilný symbol
          platby a platba je uhradená <strong>až po</strong>&nbsp;príchode
          peňazí na účet poskytovateľa. V opačnom prípade poskytovateľ nemusí
          službu poskytnúť vzhľadom na nemožnosť identifikácie platby.
          Poskytoavteľ vráti neidentifikované platby na účet príkazcu, pokiaľ je
          protiúčet k dispozícií. V prípade, že do 14 dní od úhrady nedôjde k
          označeniu faktúry v administračnom rozhraní služieb do stavu
          "ZAPLATENÉ", uživateľ musí okamžite informovať poskytovateľa a
          poskytnúť mu informácie vedúce k úspešnej identifikácií platby (dátum
          úhrady, číslo účtu, banka príkazcu a ďaľšie, ktoré si môže
          poskytovateľ vyžiadať)
        </li>
      </ol>

      <h1 class="custom-heading h-custom-color mt-20">
        X. Záverečné ustanovenia
      </h1>
      <ol type="a" class="list-decimal ml-10">
        <li>
          Zmluva a tieto VOP sú vyhotovené a uzatvárané v slovenskom jazyku. V
          prípade, že sú podpísané i v cudzojazyčnom vyhotovení, je rozhodujúce
          toto slovenské znenie.
        </li>
        <li>
          Všeobecné podmienky nadobudli platnosť a účinnosť dňom 4. augusta
          2020.
        </li>
        <li>
          Tieto všeobecné podmienky sú úplným znením po vykonaných zmenách zo
          dňa 2. augusta 2020. Toto úplne znenie VOP nadobúda účinnosť 4.
          augusta 2020.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
  },

  metaInfo() {
    return { title: "Všeobecné obchodné podmienky" };
  },
};
</script>

<style scoped></style>
